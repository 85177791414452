import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebook, FaFacebookMessenger, FaMailBulk, FaMailchimp, FaPhoneAlt, FaTelegram } from 'react-icons/fa';
import './Footer.css';
import android from '../../images/footer/playMarket.png';
import ios from '../../images/footer/appStore.png';
import call from '../../images/footer/call.png';

class Footer extends Component {
    render() {
        return (
            <div className='Footer'>
                <Container>
                    <div className='flexFooter'>
                        <div className='block'>
                            <h3>Как с нами связаться</h3>
                            <div className='callCenter'>
                                <label className='text'>Call Center</label>
                                <label className='phone'><img className='call' src={call} />44 600 60 60</label>

                            </div>
                            <label className='calls'><FaMailBulk />Info@babilon-t.com</label>
                            <label className='calls'><FaFacebook />Написать Facebook</label>
                            <label className='calls'><FaTelegram />Написать в Telegram Bot</label>
                        </div>
                        <div className='block'>
                            <h3>Услуги</h3>
                            <label className='uslug'>Домашный Интернет </label>
                            <label className='uslug'>WIMAX</label>
                            <label className='uslug'>Беспроводной LTE</label>
                            <label className='uslug'>IPTV - Телевидения </label>
                            <label className='uslug'>NGN Телефония</label>
                            <label className='uslug'>Домин /Хостинг </label>
                        </div>
                        <div className='block'>
                            <h3>Вавилон-Т</h3>
                            <label className='bt'>О компании</label>
                            <label className='bt'>Вакансии</label>
                            <label className='bt'>Акции </label>
                            <label className='bt'>Новости </label>
                            <label className='bt'>Контакты</label>
                        </div>
                        <div className='block app'>
                            <h3></h3>
                            <div className='android'>
                                <img src={android} />
                            </div>
                            <div className='ios'>
                                <img src={ios} />
                            </div>
                        </div>
                    </div>


                </Container>
                <hr />
                <div className='end'>
                <label>Политика кондифициальности</label>
                <label>© Все права защищены</label>
                </div>
            </div>
        );
    }
}

export default Footer;