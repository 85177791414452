import React, { Component } from 'react';
import './SendRequest.css';
import { Container } from 'react-bootstrap';
import women from '../../../../images/wimax/reqWoomen.png';
class SendRequest extends Component {
    render() {
        return (
            <div className='request'>
                <Container>
                    <h2>Оставьте заявку на подключение</h2>
                    <div className='flex'>
                        <div className='name'>
                            <span>Имя</span>
                            <input type='text' placeholder='Ваша имя' />
                        </div>
                        <div className='phone'>
                            <span>Телефон</span>
                            <input type='number' placeholder='+992' />
                        </div>
                        <div className='reqButton'>
                            <button>Отправить</button>
                        </div>
                        <div className='image'>
                            <img src={women} alt='img' />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default SendRequest;