import React, { useState, useEffect } from "react";
import "./Faq.css";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";

import { URL } from "../../url";

const Faq = () => {
  const [faqs, setFaqs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(`${URL}/api/faq`);
        console.log("Полученные данные FAQ:", response.data);
        setFaqs(response.data);

        setIsLoading(false);
      } catch (error) {
        console.error(
          "Ошибка при получении данных FAQ",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchFaqs();
  }, []);

  return (
    <div className="faqContainer">
      <Container>
        <div className="faq">
          <p>Часто задаваемые вопросы</p>
          {isLoading ? (
            <p>loading...</p>
          ) : (
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              {faqs?.map((faq, index) => (
                <Accordion.Item
                  className="faqItem"
                  eventKey={index.toString()}
                  key={faq.id}
                >
                  <Accordion.Header className="faqTitle">
                    {faq.question}
                  </Accordion.Header>
                  <Accordion.Body>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Faq;
