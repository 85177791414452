import React, { Component } from 'react';
import Tarrifs from '../Components/Tarrifs/Tarrifs';
import fon from '../images/tarrifs/tarrifs.png';
import './Homeinternet.css';
import AllTarrifs from '../Components/AllTarrifs/AllTarrifs';
import MapConnect from '../Components/MapConnect/MapConnect';
import Equipment from '../Components/Equipment/Equipment';
import CallMee from '../Components/CallMee/CallMee';
import Faq from '../Components/Faq/Faq';

class Homeinternet extends Component {
    render() {
        return (
            <div>
                <div className='fon'>
                <img src={fon} alt='fon'/>
                </div>
                <AllTarrifs/>
                <MapConnect/>
                <Equipment/>
                <CallMee/>
                <Faq/>
            </div>
        );
    }
}

export default Homeinternet;