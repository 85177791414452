import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Services.css";
import wimax from "../images/wimax.png";
import iptv from "../images/IPTV.png";

class Services extends Component {
	render() {
		return (
			<>
				<Container className="menuServices">
					<Row className="row">
						<Col className="col" md={3}>
							<div>
								<h4>Домашний интернет</h4>
								<label className="textSale">Скидка 50% на 6 мес.</label>
							</div>
							<div className="sale">
								<span className="sale1">-50%</span>
								<span className="sale2">150</span>
							</div>
							<div className="rotate"></div>
						</Col>
						<Col className="col" md={3}>
							<div>
								<h4>Выбери тариф</h4>
								<label>Беспроводной 4G Интернет</label>
							</div>
							<div className="sale">
								<img src={wimax} alt="wimax" />
							</div>
							<div className="rotate"></div>
							<div className="rotate"></div>
						</Col>
						<Col className="col" md={3}>
							<div>
								<h4>Выбери крепление</h4>
								<label>Подобрать крепление</label>
							</div>
							<div className="sale">
								<img src={iptv} alt="iptv" />
							</div>
							<div className="rotate"></div>
						</Col>
						<Col className="col" md={3}>
							<div>
								<h4>Телефония</h4>
								<label>НЧН ТЕЛЕФОНИ</label>
							</div>
							<div className="sale"></div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
export default Services;
