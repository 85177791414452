import React, { Component } from 'react';
import './Turbo.css';
import { Card } from 'react-bootstrap';
import turboS from '../../../images/turbo/TurboS.png';
import turboM from '../../../images/turbo/TurboM.png';
import turboL from '../../../images/turbo/TurboL.png';
import turboXL from '../../../images/turbo/TurboXL.png';

class Turbo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTimeS: '1 час',
            selectedTimeM: '1 час',
            selectedTimeL: '3 часа',
            selectedTimeXL: '1 час'
        };
    }

    handleTimeChange = (type, time) => {
        if (type === 'S') {
            this.setState({ selectedTimeS: time });
        } else if (type === 'M') {
            this.setState({ selectedTimeM: time });
        } else if (type === 'L') {
            this.setState({ selectedTimeL: time });
        } else if (type === 'XL') {
            this.setState({ selectedTimeXL: time });
        }
    };
    render() {
        const { selectedTimeS, selectedTimeM, selectedTimeL, selectedTimeXL } = this.state;

        return (
            <div className='Turbo'>
                <div className="container">
                    <div className="option">
                        <h2 className="title">Турбо S</h2>
                        <div className="speed-container">
                            <img src={turboS} alt='img'/>
                        </div>
                        <div className='main'>

                            <div className="time-options">
                                <h3 className='title-time-options'>Выберите время </h3>
                                <div className='flex'>
                                    <span>1 час</span>
                                    <label className="switch">
                                        {/* 1 час */}
                                        <input
                                            type="radio"
                                            value="1 час"
                                            checked={selectedTimeS === '1 час'}
                                            onChange={() => this.handleTimeChange('S', '1 час')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>3 часа</span>

                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="3 часа"
                                            checked={selectedTimeS === '3 часа'}
                                            onChange={() => this.handleTimeChange('S', '3 часа')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>6 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="6 часов"
                                            checked={selectedTimeS === '6 часов'}
                                            onChange={() => this.handleTimeChange('S', '6 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>12 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="12 часов"
                                            checked={selectedTimeS === '12 часов'}
                                            onChange={() => this.handleTimeChange('S', '12 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="price"> <span>10 </span>сомони</div>
                        
                        <button className="select-button">Выбрать</button>
                        <a className='footer-a' href='#'> Подробное</a>
                    </div>
                    <div className="option">
                        <h2 className="title">Турбо M</h2>
                        <div className="speed-container">
                        <img src={turboM} alt='img'/>
                        </div>
                        <div className='main'>

                            <div className="time-options">
                                <h3 className='title-time-options'>Выберите время </h3>
                                <div className='flex'>
                                    <span>1 час</span>
                                    <label className="switch">
                                        {/* 1 час */}
                                        <input
                                            type="radio"
                                            value="1 час"
                                            checked={selectedTimeM === '1 час'}
                                            onChange={() => this.handleTimeChange('M', '1 час')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>3 часа</span>

                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="3 часа"
                                            checked={selectedTimeM === '3 часа'}
                                            onChange={() => this.handleTimeChange('M', '3 часа')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>6 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="6 часов"
                                            checked={selectedTimeM === '6 часов'}
                                            onChange={() => this.handleTimeChange('M', '6 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>12 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="12 часов"
                                            checked={selectedTimeM === '12 часов'}
                                            onChange={() => this.handleTimeChange('M', '12 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="price"> <span>10 </span>сомони</div>
                        
                        <button className="select-button">Выбрать</button>
                        <a className='footer-a' href='#'> Подробное</a>
                    </div>
                    <div className="option">
                        <h2 className="title">Турбо L</h2>
                        <div className="speed-container">
                        <img src={turboL} alt='img'/>
                        </div>
                        <div className='main'>

                            <div className="time-options">
                                <h3 className='title-time-options'>Выберите время </h3>
                                <div className='flex'>
                                    <span>1 час</span>
                                    <label className="switch">
                                        {/* 1 час */}
                                        <input
                                            type="radio"
                                            value="1 час"
                                            checked={selectedTimeL === '1 час'}
                                            onChange={() => this.handleTimeChange('L', '1 час')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>3 часа</span>

                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="3 часа"
                                            checked={selectedTimeL === '3 часа'}
                                            onChange={() => this.handleTimeChange('L', '3 часа')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>6 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="6 часов"
                                            checked={selectedTimeL === '6 часов'}
                                            onChange={() => this.handleTimeChange('L', '6 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>12 часов</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="12 часов"
                                            checked={selectedTimeL === '12 часов'}
                                            onChange={() => this.handleTimeChange('L', '12 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="price"> <span>10 </span>сомони</div>
                        <button className="select-button">Выбрать</button>
                        <a className='footer-a' href='#'> Подробное</a>
                    </div>
                    <div className="option">
                        <h2 className="title">Турбо XL</h2>
                        <div className="speed-container">
                        <img src={turboXL} alt='img'/>
                        </div>
                        <div className='main'>
                            <div className="time-options">
                                <h3 className='title-time-options'>Выберите время </h3>
                                <div className='flex'>
                                    <span>1 час</span>
                                    <label className="switch">
                                        {/* 1 час */}
                                        <input
                                            type="radio"
                                            value="1 час"
                                            checked={selectedTimeXL === '1 час'}
                                            onChange={() => this.handleTimeChange('XL', '1 час')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>3 часа</span>
                                    <label className="switch">

                                        <input
                                            type="radio"
                                            value="3 часа"
                                            checked={selectedTimeXL === '3 часа'}
                                            onChange={() => this.handleTimeChange('XL', '3 часа')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>6 часов</span>
                                    <label className="switch">
                                        <input
                                            type="radio"
                                            value="6 часов"
                                            checked={selectedTimeXL === '6 часов'}
                                            onChange={() => this.handleTimeChange('XL', '6 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className='flex'>
                                    <span>12 часов</span>
                                    <label className="switch">
                                        <input
                                            type="radio"
                                            value="12 часов"
                                            checked={selectedTimeXL === '12 часов'}
                                            onChange={() => this.handleTimeChange('XL', '12 часов')}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="price"> <span>10 </span>сомони</div>
                        <button className="select-button">Выбрать</button>
                        <a className='footer-a' href='#'> Подробное</a>
                    </div>


                </div>
                
                
            </div>
        );
    }
}

export default Turbo;