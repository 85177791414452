import React, { Component } from 'react';
import './VpsTp.css';
import { Button, Container } from 'react-bootstrap';
import addres from '../../../../images/vps/map-pin.png';
import slider from '../../../../images/vps/sliders.png';
import setting from '../../../../images/vps/settings.png';
import win from '../../../../images/vps/win.png';
import LargeImg from '../../../../images/vps/imageLarge.png';
import charak1 from '../../../../images/vps/charak1.png';
import charak2 from '../../../../images/vps/charak2.png';
import Faq from '../../../Faq/Faq';
import ServerCalculator from '../../../Calculate/ServerCalculator';

class VpsTp extends Component {
	render() {
		return (
			<div className=' tarrifVps'>
				<div className='calculatePrice'>
					<Container>
					<div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px', fontSize: '14px', color: '#2E2E2E' }}>
						Интернет / LTE / <span>VPS</span>
					</div>
					<div className='vps-header'>
						<h2>Калькулятор облачных ресурсов</h2>
						<h3>коммерческое предложение за 3 минуты</h3>
					</div>
						<ServerCalculator />
					</Container>
				</div>
				<Container>
					<div className='freeForVps'>
						<h3>Бесплатно с каждым VDS и VPS сервером:</h3>
						<div className='infoVps'>
							<div className='text'>
								<p> <img src={addres} alt='img' /> 1 выделенный IP-адрес</p>
								<p> <img src={slider} alt='img' />ISPmanager 6 Lite на 1 месяц </p>
								<p> <img src={setting} alt='img' /> Тестовый период по согласованию</p>
								<p> <img src={win} alt='img' /> Установка ОС на ваш выбор</p>
							</div>
							<div className='img'>
								<img src={LargeImg} alt='img' />
							</div>
						</div>
					</div>
					<div className='technical'>
						<p>ОДДЕРЖКА И РУКОВОДСТВО</p>
						<h1>Поддержка хостинга 24/7</h1>
						<label>Работа технической поддержки — один из важных факторов выбора хостинга.
							Мы помогаем по любым вопросам, связанным с хостингом,
							доменами и SSL-сертификатам</label>
						<Button>Связаться с нами сейчас</Button>
					</div>
					<div className='charakter'>
						<p>Управляйте ресурсами самостоятельно</p>
						<div className='flex'>
							<img src={charak1} alt='img' /> 
							<img src={charak2} alt='img' />
						</div>
					</div>
					<Faq />
				</Container>
			</div>
		);
	}
}

export default VpsTp;
