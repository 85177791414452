import React, { Component } from 'react';
import './Vps.css';
import { Container } from 'react-bootstrap';
import VpsTp from '../Components/Tarrifs/Dushanbe/VpsTP/VpsTp';

class Vps extends Component {
    render() {
        return (
            <div className='myVps'>
                <div className='fonVps'>
                    <Container>
                        <h1>Виртуальный <br /> сервер VPS</h1>

                    </Container>
                </div>
                <VpsTp />
            </div>
        );
    }
}

export default Vps;