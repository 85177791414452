import React, { Component } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import './SelectedTarrif.css';
import wifiRouter from '../../images/equipment/wifiRouter.png';
import wifiRouter1 from '../../images/equipment/wifiRouter1.png';
import wifiRouter2 from '../../images/equipment/wifiRouter2.png';
import wifiRouter3 from '../../images/equipment/wifiRouter3.png';
import cyberXXL from '../../images/cyberXXL.png';
import sotSet from '../../images/sendTarrif.png';
import SotSS from '../../images/sotSS.png';

const tariffs = [
    {
        name: 'Cyber L',
        speed: '1 Мбит/с',
        traffic: 'Безлимитный',
        accessSpeed: '10 Мбит/с',
        price: 560,
        term: 'месяц',
        optom: {
            name: 'Оптом L',
            packages: [
                {
                    term: '3 месяца',
                    discount: '-5%',
                    price: 420
                },
                {
                    term: '6 месяцев',
                    discount: '-10%',
                    price: 400
                },
                {
                    term: '12 месяцев',
                    discount: '-20%',
                    price: 360
                }
            ]
        }
    },
    {
        name: 'Cyber XXL',
        speed: '1 Мбит/с',
        traffic: 'Безлимитный',
        accessSpeed: '10 Мбит/с',
        price: 560,
        term: 'месяц',
        optom: {
            name: 'Оптом XXL',
            packages: [
                {
                    term: '3 месяца',
                    discount: '-5%',
                    price: 520
                },
                {
                    term: '6 месяцев',
                    discount: '-10%',
                    price: 500
                },
                {
                    term: '12 месяцев',
                    discount: '-20%',
                    price: 360
                }
            ]
        }
    },
    {
        name: 'Cyber M',
        speed: '1 Мбит/с',
        traffic: 'Безлимитный',
        accessSpeed: '10 Мбит/с',
        price: 560,
        term: 'месяц',
        optom: {
            name: 'Оптом M',
            packages: [
                {
                    term: '3 месяца',
                    discount: '-5%',
                    price: 520
                },
                {
                    term: '6 месяцев',
                    discount: '-10%',
                    price: 500
                },
                {
                    term: '12 месяцев',
                    discount: '-20%',
                    price: 360
                }
            ]
        }
    }
];

const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        return <Component {...props} params={params} />;
    };
};

class SelectedTarrif extends Component {
    constructor(props) {
        super(props);
        const tariffName = props.params.tariffName;
        const selectedTariff = tariffs.find((tariff) => tariff.name === tariffName);
        this.state = {
            selectedTariff,
            selectedPay: 'pay',
            selectedPackage: null,
            selectedTariffSelected: false,
            priceNoCount: null
        };
        this.handlePayChange = this.handlePayChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick(packageDetails) {
        if (packageDetails) {
            this.setState({ selectedPackage: packageDetails, selectedTariffSelected: false });
            console.log('Selected Package Details:', packageDetails);
        } else {
            this.setState({ selectedTariffSelected: true, selectedPackage: null });
            console.log('Selected Tariff:', this.state.selectedTariff);
        }
    }

    handlePayChange(event) {
        const newPay = event.target.value;
        this.setState({ selectedPay: newPay });
        console.log(newPay);
    }

    handleSubmit(event) {
        event.preventDefault();
        const { selectedTariff, selectedPackage, selectedTariffSelected } = this.state;
        if (selectedTariffSelected) {
            console.log('Selected Tariff:', selectedTariff);
        } else if (selectedPackage) {
            console.log('Selected Package:', selectedPackage);


        } else {
            console.log('No tariff or package selected');
        }
    }

    renderPayInfo() {
        switch (this.state.selectedPay) {
            case 'pay':
                return <label>450</label>;
            case 'credit':
                return <label>30</label>;
            default:
                return <p>Пожалуйста, выберите город.</p>;
        }
    }

    render() {
        const { selectedTariff, selectedPackage, selectedTariffSelected } = this.state;
        const priceTarif = selectedTariff.price;


        console.log("hh", selectedTariff);
        if (selectedPackage) {
            const allmonth = selectedPackage.term.replace(/\D/g, '');
            const priceNoCount = allmonth * priceTarif;
            console.log("hbdhbvd", allmonth, priceNoCount);

        }

        if (!selectedTariff) {
            return <div>Тариф не найден</div>;
        }

        return (
            <div className='sendRequest'>
                <div className='banner'>
                    <h3>Тарифный План {selectedTariff.name}</h3>
                </div>
                <Container>
                    <div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
                        Интернет / Домашний интернет / Тарифы / <span style={{ color: '#9A9CA5' }}>Тариф {selectedTariff.name}</span>
                    </div>

                    <div className='subTitle'>
                        <p>Выбранный вами Тариф {selectedTariff.name}</p>
                    </div>
                    <div className='flex'>
                        <div className='sendTarrif'>
                            <div className='thisTarrif' style={{ backgroundColor: selectedTariffSelected ? '#00ABF4' : 'transparent' }}>
                                <div>
                                    <p>{selectedTariff.name}</p>
                                    <div className='imgXL'>
                                        <img src={cyberXXL} alt={selectedTariff.name} />
                                    </div>
                                </div>
                                <div className='thisTarrif1'>
                                    <div className='options1'>
                                        <ol>
                                            <li className='speed'>
                                                <span className='span'>Скорость</span>
                                                <h4>{selectedTariff.speed}</h4>
                                            </li>
                                            <li>
                                                <span className='span'>Интернет Трафик </span>
                                                <h4>{selectedTariff.traffic}</h4>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className='options2'>
                                        <ol>
                                            <li>
                                                <span className='span'>Скорость доступа на</span>
                                                <span className='img'>
                                                    <img src={sotSet} alt="sotSet" />
                                                </span>
                                                <br />
                                                <h4>{selectedTariff.accessSpeed}</h4>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className='options3'>
                                        <div className='footer'>
                                            <div className='price'>
                                                <h3>{selectedTariff.price}</h3>
                                                <span className='span'>сом/{selectedTariff.term}</span>
                                            </div>
                                            <Button style={{ backgroundColor: selectedTariffSelected ? '#ffffff' : '#494949', color: selectedTariffSelected ? '#494949' : '#ffffff' }} className='Button' variant="primary" onClick={() => this.handleClick(null)}>
                                                {selectedTariffSelected ? 'Выбрано' : 'Выбрать'}
                                            </Button>{' '}                                        <a href='#'>Примечание</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='subTitle'>
                                <p>Выгоднее подключить пакет {selectedTariff.optom.name}:</p>
                            </div>
                            <div className='thisTarrifOptom'>
                                {selectedTariff.optom.packages.map((pkg, pkgIndex) => (
                                    <div key={pkgIndex}
                                        className='Month'
                                        style={{ backgroundColor: selectedPackage === pkg ? '#00ABF4' : 'transparent', color: selectedPackage === pkg ? '#ffffff !important' : '#ffffff' }}>
                                        <div className='discount'>
                                            <label>{pkg.discount}</label>
                                        </div>
                                        <div className='tarrifName'>
                                            <span>Абонемент на {pkg.term}:</span>
                                            <h3>{selectedTariff.optom.name}</h3>
                                        </div>
                                        <div className='price'>
                                            <h3>{pkg.price}</h3>
                                            <span className='span'>сом/месяц</span>
                                        </div>
                                        <div className='button'>
                                            <Button style={{ backgroundColor: selectedPackage === pkg ? '#ffffff' : '#494949', color: selectedPackage === pkg ? '#494949' : '#ffffff' }} className='Button' variant="primary" onClick={() => this.handleClick(pkg)}>{selectedPackage === pkg ? 'Выбрано' : 'Выбрать'}</Button>{' '}
                                            <a href='#'>Примечание</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <form action='#'>
                            <div className='sendForm'>
                                <div className='form'>
                                    <div className='header'>
                                        <h3>Ваша заявка</h3>
                                        <hr />
                                    </div>
                                    <div className='main'>
                                        <div className='priceMonth'>
                                            <label>Ценна за 1 месяц</label>
                                            <label> <span>{priceTarif}</span>сомони </label>
                                        </div>
                                        {selectedPackage && (
                                            <div className='priceOptom'>
                                                <label>Ценна за {selectedPackage.term} </label>
                                                <div className='sumOptom'>
                                                    <label className='disabled'> <span>{selectedPackage.term.replace(/\D/g, '') * priceTarif}</span>сомони </label>
                                                    <label> <span>{selectedPackage.term.replace(/\D/g, '') * selectedPackage.price} </span>сомони</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='economy'>
                                        {selectedPackage && (
                                            <div className='priceEconom'>
                                                <label>Экономия</label>
                                                <label> <span>{(selectedPackage.term.replace(/\D/g, '') * priceTarif) - (selectedPackage.term.replace(/\D/g, '') * selectedPackage.price)} </span>сомони</label>
                                            </div>
                                        )}
                                        <div className='summa'>
                                            <label>Итого </label>
                                            <label> <span>{!selectedPackage ? priceTarif : selectedPackage.term.replace(/\D/g, '') * selectedPackage.price} </span>сомони</label>
                                        </div>
                                    </div>
                                    <div className='footer'>
                                        <input type='submit' value='  Отправить' />
                                    </div>
                                </div>
                                <div className='IPTV'>
                                    <div className='checkBox'>
                                        <h3>IPTV</h3>
                                        <Form>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                className="custom-switch"
                                            />
                                        </Form>
                                    </div>
                                    <div className='price'>
                                        <label> 30 <span>сомони</span></label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Container>
                <div className='inputs'>
                    <Container>
                        <h3>Укажите контактные данные <span style={{ color: 'red' }}>*</span></h3>
                        <div className='addRess'>
                            <div className='selectSity'>
                                <h4>Укажите адрес</h4>
                                <Form.Select aria-label="Default select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </div>
                            <input className='strit' type='text' name='strit' placeholder='Улица' />
                            <input className='dom' type='text' name='dom' placeholder='Дом' />
                            <input className='room' type='text' name='room' placeholder='кв.' />
                        </div>
                        <div className='nameNumber'>
                            <div className='name'>
                                <h4>Укажите Имя</h4>
                                <input type='text' name='name' placeholder='' />
                            </div>
                            <div className='number'>
                                <h4>Номер телефона</h4>
                                <input type='text' name='name' placeholder='+992' />
                            </div>
                        </div>
                    </Container>
                </div>
                <div className='Equipment'>
                    <Container>
                        <div className='title'>
                            <h2>Оборудование</h2>
                        </div>
                        <Container>
                            <div className='cards'>
                                <Card className='myCard'>
                                    <Card.Img className='img' src={wifiRouter} />
                                    <Card.Body>
                                        <span className='subTitle'>WiFi Роутер</span>
                                        <Card.Title className='cardTitle'>D-Link DIR-842/R7</Card.Title>
                                        <Card.Text className='cardText first'>
                                            Скорость соединения ......<span>до 300 Мбит/с</span>
                                        </Card.Text>
                                        <Card.Text className='cardText'>Площадь покрытия ................ <span>до 70 кв.м </span></Card.Text>
                                        <Card.Text className='cardText'>Поддерживает .............. <span>до 10 устройств</span></Card.Text>
                                        <select className='selectPay' value={this.state.selectedPay} onChange={this.handlePayChange}>
                                            <option value="pay">Наличными</option>
                                            <option value="credit">Рассрочка</option>
                                        </select>
                                        <div className='link'>
                                            <div>
                                                <div className='text'>
                                                    {this.renderPayInfo()}
                                                    <label>сом</label>
                                                </div>
                                            </div>
                                            <Button className='button'>Выбрать</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='myCard'>
                                    <Card.Img className='img' src={wifiRouter1} />
                                    <Card.Body>
                                        <span className='subTitle'>WiFi Роутер</span>
                                        <Card.Title className='cardTitle'>D-Link DIR-842/R7</Card.Title>
                                        <Card.Text className='cardText first'>
                                            Скорость соединения ......<span>до 300 Мбит/с</span>
                                        </Card.Text>
                                        <Card.Text className='cardText'>Площадь покрытия ................ <span>до 70 кв.м </span></Card.Text>
                                        <Card.Text className='cardText'>Поддерживает .............. <span>до 10 устройств</span></Card.Text>
                                        <select className='selectPay' value={this.state.selectedPay} onChange={this.handlePayChange}>
                                            <option value="pay">Наличными</option>
                                            <option value="credit">Рассрочка</option>
                                        </select>
                                        <div className='link'>
                                            <div>
                                                <div className='text'>
                                                    {this.renderPayInfo()}
                                                    <label>сом</label>
                                                </div>
                                            </div>
                                            <Button className='button'>Выбрать</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='myCard'>
                                    <Card.Img className='img' src={wifiRouter2} />
                                    <Card.Body>
                                        <span className='subTitle'>WiFi Роутер</span>
                                        <Card.Title className='cardTitle'>D-Link DIR-842/R7</Card.Title>
                                        <Card.Text className='cardText first'>
                                            Скорость соединения ......<span>до 300 Мбит/с</span>
                                        </Card.Text>
                                        <Card.Text className='cardText'>Площадь покрытия ................ <span>до 70 кв.м </span></Card.Text>
                                        <Card.Text className='cardText'>Поддерживает .............. <span>до 10 устройств</span></Card.Text>
                                        <select className='selectPay' value={this.state.selectedPay} onChange={this.handlePayChange}>
                                            <option value="pay">Наличными</option>
                                            <option value="credit">Рассрочка</option>
                                        </select>
                                        <div className='link'>
                                            <div>
                                                <div className='text'>
                                                    {this.renderPayInfo()}
                                                    <label>сом</label>
                                                </div>
                                            </div>
                                            <Button className='button'>Выбрать</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Container>
                    </Container>
                </div>
            </div>
        );
    }
}

export default withRouter(SelectedTarrif);
