import React, { Component } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Импортируем withRouter для доступа к истории маршрутизации
import './Optom.css';
// import './Dushanbe.css';
import sotSet from '../../../images/sotSet.png';
import SotSet from '../../../images/sotSet1.png';
import SotSS from '../../../images/sotSS.png';
import cyberS from '../../../images/cyberS.png';
import cyberM from '../../../images/cyberM.png';
import cyberL from '../../../images/cyberL.png';
import cyberXL from '../../../images/cyberXL.png';
import cyberXXL from '../../../images/cyberXXL.png';


const Optom = () => {
    const navigate = useNavigate();

    const handleSelectTariff = (tariffName) => {
        navigate(`/connection/${tariffName}`);
    }
    // render() {
        return (
            <>
                <Container>
                    <div className='blockTarif'>
                        <div className='card'>
                            <p>Optom S</p>
                            <div className='img'>
                                {/* <img src={cyberS} /> */}
                            </div>
                            <div className='options'>
                                <ol>
                                    <li>
                                        <span className='span'>Скорость</span>
                                        <h4>1 МБить/с</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Интернет Трафик </span>
                                        <h4>Безлимитный</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Скорость доступа на</span>
                                        <span className='img'><img src={sotSet} /></span><br />
                                        <span className='img1'><img src={SotSS} /></span><br />
                                        <h4>10 Мбит/с</h4>
                                    </li>

                                </ol>
                                <select>
                                    <option value='12'>Цена за 12 месяцев</option>
                                    <option value='6'>Цена за 6 месяцев</option>
                                    <option value='3'>Цена за 3 месяца</option>
                                </select>
                                <div className='footer'>
                                    <div className='priceOptom'>
                                        <h2> <span>140</span></h2><h3> 120</h3><span className='span'>сом <br/>мес</span>
                                    </div>
                                    <Button className='Button' variant="primary" onClick={() => handleSelectTariff('Optom S')}>Выбрать</Button>{' '}
                                    <a href='#'>Подробное</a>
                                </div>
                            </div>

                        </div>
                        <div className='card'>
                            <p>Optom M</p>
                            <div className='img'>
                                {/* <img src={cyberM} /> */}
                            </div>
                            <div className='options'>
                                <ol>
                                    <li>
                                        <span className='span'>Скорость</span>
                                        <h4>1 МБить/с</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Интернет Трафик </span>
                                        <h4>Безлимитный</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Скорость доступа на</span>
                                        <span className='img'><img src={sotSet} /></span><br />
                                        <span className='img1'><img src={SotSS} /></span><br />
                                        <h4>10 Мбит/с</h4>
                                    </li>

                                </ol>
                                <select>
                                    <option value='12'>Цена за 12 месяцев</option>
                                    <option value='6'>Цена за 6 месяцев</option>
                                    <option value='3'>Цена за 3 месяца</option>
                                </select>
                                <div className='footer'>
                                <div className='priceOptom'>
                                        <h2> <span>1440</span></h2><h3> 1450</h3><span className='span'>сом <br/>мес</span>
                                    </div>
                                    <Button className='Button' variant="primary" onClick={() => handleSelectTariff('Optom M')}>Выбрать</Button>{' '}
                                    <a href='/connection'>Подробное</a>
                                </div>
                            </div>

                        </div>
                        <div className='card'>
                            <p>Optom L</p>
                            <div className='img'>
                                {/* <img src={cyberL} /> */}
                            </div>
                            <div className='options'>
                                <ol>
                                    <li>
                                        <span className='span'>Скорость</span>
                                        <h4>1 МБить/с</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Интернет Трафик </span>
                                        <h4>Безлимитный</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Скорость доступа на</span>
                                        <span className='img'><img src={sotSet} /></span><br />
                                        <span className='img1'><img src={SotSS} /></span><br />
                                        <h4>10 Мбит/с</h4>
                                    </li>

                                </ol>
                                <select>
                                    <option value='12'>Цена за 12 месяцев</option>
                                    <option value='6'>Цена за 6 месяцев</option>
                                    <option value='3'>Цена за 3 месяца</option>
                                </select>
                                <div className='footer'>
                                <div className='priceOptom'>
                                        <h2> <span>1440</span></h2><h3> 1450</h3><span className='span'>сом <br/>мес</span>
                                    </div>
                                    <Button className='Button' variant="primary" onClick={() => handleSelectTariff('Optom L')}>Выбрать</Button>{' '}
                                    <a href='#'>Подробное</a>
                                </div>
                                
                            </div>

                        </div>
                        <div className='card recom'>
                            <p>Optom XL</p>
                            <div className='img'>
                                {/* <img src={cyberXL} /> */}
                            </div>
                            <div className='options'>
                                <ol>
                                    <li>
                                        <span className='span'>Скорость</span>
                                        <h4>1 МБить/с</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Интернет Трафик </span>
                                        <h4>Безлимитный</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Скорость доступа на</span>
                                        <span className='imgS'><img src={SotSet} alt='sotlet'/></span><br />
                                        <h4>10 Мбит/с</h4>
                                    </li>

                                </ol>
                                <select>
                                    <option value='12'>Цена за 12 месяцев</option>
                                    <option value='6'>Цена за 6 месяцев</option>
                                    <option value='3'>Цена за 3 месяца</option>
                                </select>
                                <div className='footer'>
                                <div className='priceOptom'>
                                        <h2> <span>1440</span></h2><h3> 1450</h3><span className='span'>сом <br/>мес</span>
                                    </div>
                                    <Button className='Button' variant="primary" onClick={() => handleSelectTariff('Optom XL')}>Выбрать</Button>{' '}
                                    <a href='#'>Подробное</a>
                                </div>
                            </div>
                                <div className='recBlock'>
                                    <label>Рекомендуем</label>
                                </div>

                        </div>
                        <div className='card'>
                            <p>Optom XXL</p>
                            <div className='imgXL '>
                                {/* <img src={cyberXXL} /> */}
                            </div>
                            <div className='options'>
                                <ol>
                                    <li>
                                        <span className='span'>Скорость</span>
                                        <h4>1 МБить/с</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Интернет Трафик </span>
                                        <h4>Безлимитный</h4>
                                    </li>
                                    <li>
                                        <span className='span'>Скорость доступа на</span>
                                        <span className='img'><img src={sotSet} alt='sotlet' /></span><br />
                                        <span className='img1'><img src={SotSS} alt='sotSS'/></span><br />
                                        
                                        <h4>10 Мбит/с</h4>
                                    </li>
                                </ol>
                                <select>
                                    <option value='12'>Цена за 12 месяцев</option>
                                    <option value='6'>Цена за 6 месяцев</option>
                                    <option value='3'>Цена за 3 месяца</option>
                                </select>
                                <div className='footer'>
                                <div className='priceOptom'>
                                        <h2> <span>1440</span></h2><h3> 1450</h3><span className='span'>сом <br/>мес</span>
                                    </div>
                                    <Button className='Button' variant="primary" onClick={() => handleSelectTariff('Optom XXL')}>Выбрать</Button>{' '}
                                    <a href='#'>Подробное</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </>
        );
    }
// }

export default Optom;
