import React, { Component } from 'react';
import './DomainTp.css';
import { Container } from 'react-bootstrap';

import securityImg from '../../../../images/domain/securDomain.png';
import Faq from '../../../Faq/Faq';

class DomainTp extends Component {
    render() {
        const data = [
            { zone: 'TJ', name: 'Таджикская зона', description: 'Самая популярная в Таджикистане', registration: '61,33 сомони', annualFee: '241 сомони / год' },
            { zone: 'COM', name: 'Международная зона', description: 'Для успешного взлёта бизнеса', registration: '61,33 сомони', annualFee: '241 сомони / год', annual: '?' },
            { zone: 'ORG', name: 'Международная зона', description: 'Для успешного взлёта бизнеса', registration: '61,33 сомони', annualFee: '241 сомони / год' },
            { zone: 'NET', name: 'Международная зона', description: 'Для успешного взлёта бизнеса', registration: '61,33 сомони', annualFee: '241 сомони / год' },
            { zone: 'UZ', name: 'Узбекская зона', description: 'Для успешного взлёта бизнеса', registration: '61,33 сомони', annualFee: '241 сомони / год', annual: '?' },
        ];
        return (
            <div>
                <Container>
                    <div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
                        Интернет / Доп Услуги / Домен
                    </div>

                
                    <div className="domain-zones-container">
                        <h1>Домен зоны</h1>
                        <table className="domain-zones-table">
                            <thead>
                                <tr>
                                    <th className='start'>Зона</th>
                                    <th>Регистрация</th>
                                    <th>Оплата за год</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="zone">
                                                <div className={`zone-icon zone-icon-${item.zone.toLowerCase()}`}>{item.zone}</div>
                                                <div>
                                                    <div className="zone-name">{item.name}</div>
                                                    <div className="zone-description">{item.description}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.registration}</td>
                                        <td>{item.annualFee}</td>
                                        <td>{item.annual}</td>
                                        <td className='button'>
                                            <button className={`apply-button apply-button-${item.zone.toLowerCase()}`}>Подать заявку</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='searchDomain'>
                        <h3>Проверить домен</h3>
                        <input type='text' value="" placeholder='.tj' />
                        <button>Проверить</button>
                    </div>

                    <div className='security'>
                        <div className='text'>
                                <h2>Уровни безопасности доменов</h2>
                            
                            <label> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Мы уделяем особое внимание безопасности и заботимся о стабильной работе доменов и сайтов наших клиентов.
                                 По умолчанию для всех доменов установлен «Обязательный» уровень безопасности. «Повышенный» .</label>
                        </div>
                        <div className='image'>
                            <img src={securityImg} />
                        </div>
                    </div>
                    <div className='securCenter'><a href='#'>Подробное описание опций безопасности</a></div>
                </Container>
                <Faq/>
            </div>
        );
    }
}

export default DomainTp;