import React, { Component } from 'react';
import './CallMee.css';
import { Container } from 'react-bootstrap';
class CallMee extends Component {
    render() {
        return (
            <div className='callMee' style={{backgroundColor:'#ED8001'}}>
                <Container>
                    <div className='callBlock'>
                        <div className='callText'>
                            <p> Введите телефон, и мы перезвоним прямо сейчас!</p>
                        </div>
                        <div>
                            <div className='callInput'>
                                <input placeholder='+992' />
                                <button>
                                    Жду звонка
                                </button>
                            </div>
                            <div className='callFooter'>
                                <p>Наши специалисты подберут, привезут и установят все необходимое</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default CallMee;