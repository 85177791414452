import React, { Component } from "react";


class Myip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: "",
    };
  }

  componentDidMount() {
    fetch("https://api.ipify.org")
      .then((response) => response.json())
      .then((data) => this.setState({ ip: data.ip }))
      .catch((error) => console.log("Ошибка:", error));
  }

  render() {
    const { ip } = this.state;
    return (
      <div>
        {ip ? (
          <span className="youIp">Ваш IP: {ip}</span>
        ) : (
          <span className="youIp">Загрузка...</span>
        )}
      </div>
    );
  }
}

export default Myip;
