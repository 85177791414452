import React, { Component } from 'react';
import './CyberStart.css';
import { Button, Container } from 'react-bootstrap';

class CyberStart extends Component {
  componentDidMount() {
    this.updateAllProgress();
  }

  updateProgress = (id, current, max) => {
    const circle = document.querySelector(`.progress-ring__circle-${id}`);
    const radius = circle.r.baseVal.value;
    const circumference = 2 * Math.PI * radius;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    const offset = circumference - (current / max) * circumference;
    setTimeout(() => {
      circle.style.transition = 'stroke-dashoffset 1s ease-in-out';
      circle.style.strokeDashoffset = offset;
    }, 100);
  }

  updateAllProgress = () => {
    const tariffs = this.getTariffs();
    tariffs.forEach((tariff, index) => {
      this.updateProgress(index, tariff.current, tariff.max);
    });
  }

  getTariffs = () => {
    return [
      { name: 'CyberStart 2', current: 4, max: 30, speed: '1 МБить/с', limit: '0,186', price: '43,37', borderColor: '#979797' },
      { name: 'CyberStart 3', current: 9, max: 30, speed: '1 МБить/с', limit: '0,186', price: '43,37', borderColor: '#979797' },
      { name: 'CyberStart 4', current: 12, max: 30, speed: '1 МБить/с', limit: '0,186', price: '43,37', borderColor: '#979797' },
      { name: 'CyberStart 5', current: 20, max: 30, speed: '1 МБить/с', limit: '0,186', price: '43,37', borderColor: '#979797',backgroundColor: '#FF4242' },
      { name: 'CyberStart 6', current: 27, max: 30, speed: '1 МБить/с', limit: '0,186', price: '43,37', borderColor: '#979797' }
    ];
  }

  render() {
    const tariffs = this.getTariffs();
    return (
      <>
        <Container>
          <div className='AllTariff'>
            {tariffs.map((tariff, index) => (
              <div key={index} className="cyber-start-container" style={{ border: `1px solid ${tariff.borderColor}` }}>
                <div className="cyber-start-header">{tariff.name}</div>
                <div className="cyber-start-usage">
                  <div className="cyber-start-circle">
                    <svg className="progress-ring" width="120" height="120">
                      <circle
                        className={`progress-ring__circle-bg progress-ring__circle-bg-${index}`}
                        stroke="black"
                        strokeWidth="10"
                        fill="#ffffff"
                        r="52"
                        cx="60"
                        cy="60"
                      />
                      <circle
                        className={`progress-ring__circle progress-ring__circle-${index}`}
                        stroke="#FF5555"
                        strokeWidth="10"
                        fill="transparent"
                        r="52"
                        cx="60"
                        cy="60"
                      />
                    </svg>
                    <div className="cyber-start-text">{tariff.current} ГБ</div>
                  </div>
                </div>
                <div className="cyber-start-details">
                  <ol>
                    <li>
                      <span className='span'>Скорость</span>
                      <h4>{tariff.speed}</h4>
                    </li>
                    <li>
                      <span className='span'>При исчерпании лимита за 1МВ</span>
                      <div className='flex'><h4>{tariff.limit}</h4> <span className='span'>сомони</span></div>
                    </li>
                  </ol>
                </div>
                <div className="cyber-start-price">
                  <span className='startPrice'>{tariff.price}</span> 
                  <span className='startPriceText'>сомони в месяц</span>
                </div>
                <div className='footer'>
                  <Button className="cyber-start-button">Приобрести</Button>
                  <div className="cyber-start-more">Подробнее</div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </>
    );
  }
}

export default CyberStart;
