import React, { Component } from "react";
import "./MapConnect.css";
import { Container } from "react-bootstrap";

class MapConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      street: "",
      house: "",
      apartment: "",
      address: "",
      available: null, 
      error: null, 
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCheck = async () => {
    const { city, street, house, apartment } = this.state;
    const address = `${city}, ${street} ${house}, ${apartment}`;
    this.setState({ address });

    const secretKey = "code"; 
    const timestamp = Math.floor(Date.now() / 1000);
    const hash = await this.generateHMAC(timestamp.toString(), secretKey);

    const headers = {
      "X-Timestamp": timestamp,
      "X-Hash": hash,
    };

    fetch(
      `http://127.0.0.1:8000/get/map/city?address=${encodeURIComponent(
        address
      )}`,
      {
        method: "GET", 
        headers: headers,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Сетевая ошибка"); 
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ available: data.available, error: null }); 
      })
      .catch((error) => {
        this.setState({ error: error.message, available: null });
      });
  };

  generateHMAC = async (message, secretKey) => {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(secretKey);
    const messageData = encoder.encode(message);

    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyData,
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign"]
    );

    const signature = await window.crypto.subtle.sign(
      "HMAC",
      cryptoKey,
      messageData
    );

    return Array.from(new Uint8Array(signature))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };

  render() {
    const { city, street, house, apartment, address, available, error } =
      this.state;
    const cities = ["Душанбе", "Худжанд", "Бохтар"];
    return (
      <div className="mapBody">
        <Container>
          <div className="mapCard">
            <div className="mapTitle">
              <h1>Проверьте возможность подключения</h1>
            </div>
            <div className="blockInput" style={{ margin: "20px 0" }}>
              <div className="select">
                <p>Укажите адрес</p>
                <select
                  name="city"
                  value={city}
                  onChange={this.handleInputChange}
                  style={{
                    marginRight: "20px",
                    padding: "10px",
                    width: "263px",
                  }}
                >
                  <option value="" disabled>
                    Город
                  </option>
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                name="street"
                value={street}
                onChange={this.handleInputChange}
                placeholder="Улица"
                style={{ padding: "10px", width: "235px", color: "grey" }}
              />
              <input
                type="text"
                name="house"
                value={house}
                onChange={this.handleInputChange}
                placeholder="Дом"
                style={{ padding: "10px", width: "179px", color: "grey" }}
              />
              <input
                type="text"
                name="apartment"
                value={apartment}
                onChange={this.handleInputChange}
                placeholder="Квартира"
                style={{
                  marginRight: "100px",
                  padding: "10px",
                  width: "130px",
                  color: "grey",
                }}
              />
              <button onClick={this.handleCheck}>Проверить</button>
            </div>
            {address && (
              <div className="finalAddress">
                <p>
                  <i className="fas fa-map-marker-alt"></i> {address}
                </p>
              </div>
            )}
            {available !== null && (
              <div className="availabilityStatus">
                <p>
                  Доступность подключения:{" "}
                  {available ? "Доступно" : "Недоступно"}
                </p>
              </div>
            )}
            {error && (
              <div className="error">
                <p style={{ color: "black", fontSize:"25px" }}>{error}</p>
              </div>
            )}
            <div className="footerText">
              <p>
                Введите свой адрес, чтобы проверить, доступен ли по нему
                домашний Интернет
              </p>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default MapConnect;
