import React, { Component } from 'react';
import './HostingTp.css';
import { Container } from 'react-bootstrap';
import cloud from '../../../../images/hosting/cloudIcon.png';
import cloudHover from '../../../../images/hosting/cloudiconHover.png';
import check from '../../../../images/hosting/checkIcon.png';
import checkHover from '../../../../images/hosting/checkHover.png';
import user from '../../../../images/hosting/user-plus.png';
import domen from '../../../../images/hosting/domen.png';
import ssl from '../../../../images/hosting/ssl.png';
import women from '../../../../images/wimax/reqWoomen.png';
import Faq from '../../../Faq/Faq';

class HostingTp extends Component {
    render() {
        const plans = [
            {
                size: '20 MB',
                plan: 'Начальный',
                features: ['FTP', 'PHP', 'MySql', 'Нет'],
                price: '30,65',
            },
            {
                size: '20 MB',
                plan: 'Начальный',
                features: ['FTP', 'PHP', 'MySql', 'Нет'],
                price: '30,65',
            },
            {
                size: '20 MB',
                plan: 'Начальный',
                features: ['FTP', 'PHP', 'MySql', 'Нет'],
                price: '30,65',
            },
            {
                size: '20 MB',
                plan: 'Начальный',
                features: ['FTP', 'PHP', 'MySql', 'Нет'],
                price: '30,65',
            },
            {
                size: '20 MB',
                plan: 'Начальный',
                features: ['FTP', 'PHP', 'MySql', 'Нет'],
                price: '30,65',
            },
        ];
        return (
            <div>
                <Container>
                    <div style={{ marginTop: '20px', fontWeight: 'normal', marginBottom: '20px' }}>
                        Интернет / Доп Услуги / Хостинг
                    </div>

                    <h3>Хостинг для сайта</h3>

                    <div className='tarrifHost'>

                    </div>
                    <div className="pricing-component">
                        <div className="pricing-cards-container">
                            {plans.map((plan, index) => (
                                <div className="pricing-card" key={index}>
                                    <div className="icon-container">
                                        <i className="icon"><img src={cloud} alt='img' /> </i>
                                        <i className="hover-icon"> <img src={cloudHover} alt='img' /></i>
                                    </div>
                                    <div className="details">
                                        <h3>{plan.size}</h3>
                                        <p>{plan.plan}</p>
                                        <ul>
                                            {plan.features.map((feature, idx) => (
                                                <li key={idx}>
                                                    <span className="icon"> <img src={check} alt='img' /></span>
                                                    <span className="hover-icon"> <img src={checkHover} alt='img' /></span>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="price">
                                            <p>{plan.price} <br /> <span>сомони/месяц</span> </p>
                                        </div>
                                        <button>Подключить</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='dopUslugHost'>
                        <p>Дополнительные услуги</p>
                        <div className='cards'>
                            <div className='card'>
                                <div className='img'><img src={user} alt='img' /></div>

                                <h3>Администрирование хостинга</h3>
                                <label>Доверьте настройку вашего
                                    хостинга специалистам
                                    RU-CENTER</label>

                                <a href='#'>Заказать</a>
                            </div>

                            <div className='card'>
                                <div><img src={domen} alt='img' /></div>

                                <h3>Настройка домена</h3>
                                <label>Выполним все необходимые
                                    операции для работы
                                    домена за вас</label>

                                <a href='#'>Заказать</a>
                            </div>

                            <div className='card'>
                                <div><img src={ssl} alt='img' /></div>

                                <h3>SSL-сертификаты</h3>
                                <label>Обеспечьте безопасное
                                    соединение пользователей
                                    с вашим сайтом</label>

                                <a href='#'>Заказать</a>
                            </div>
                        </div>

                    </div>
                </Container>

                <div className='request' style={{ backgroundColor: '#1573FFCC' }}>
                    <Container>
                        <h2>Оставьте заявку на подключение</h2>
                        <div className='flex'>
                            <div className='name'>
                                <span>Имя</span>
                                <input type='text' placeholder='Ваша имя' />
                            </div>
                            <div className='phone'>
                                <span>Телефон</span>
                                <input type='number' placeholder='+992' />
                            </div>
                            <div className='reqButton'>
                                <button>Отправить</button>
                            </div>
                            <div className='image'>
                                <img src={women} alt='img' />
                            </div>
                        </div>
                    </Container>
                </div>
                <Faq/>
            </div>
        );
    }
}

export default HostingTp;